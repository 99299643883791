import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowForwardIos,
  MdBookmark,
  MdBookmarkBorder,
  MdClose,
  MdMenu,
  MdOutlineShoppingCart,
  MdPerson,
  MdPersonOutline,
  MdSearch,
  MdShoppingCart,
} from "react-icons/md";

import "../styles/Home.css";
import "../styles/Guests.css";
import { isEmpty } from "../functions/isEmpty";
import { useLocation, useNavigate } from "react-router";
import { useRef } from "react";
import Cart from "./Cart";
import Spinner from "../components/CTA/Spinner";

export default function Home({
  isMobile,
  apiURL,
  authToken,
  products,
  cart,
  setCart,
  searchInput,
  setSearchInput,
  scrollPosition,
  setScrollPosition,
  guests,
  setGuests,
  savedParteyIDs,
  setSavedParteyIDs,
  parteys,
  setReloadFilteredParteys,
  reloadFilteredParteys,
  activeParteyActions,
  setActiveParteyActions,
  setDate,
  setPlace,
  date,
  place,
  activePartey,
  setActivePartey,
  setParteyActions,
}) {
  let navigate = useNavigate();
  let location = useLocation().pathname;
  const [closestElement, setClosestElement] = useState(null);
  const scrollbarRef = useRef(null);
  const [searchActive, setSearchActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const addSavedIDTOLocalStorage = (idNum) => {
    if (savedParteyIDs != null && !savedParteyIDs.includes(idNum)) {
      let helpArr = [...savedParteyIDs];
      helpArr.push(idNum);
      setSavedParteyIDs(helpArr);
      setReloadFilteredParteys(!reloadFilteredParteys);
      localStorage.setItem("@parteyIDs", JSON.stringify(helpArr));
      console.log("saved");
    } else {
      console.log(savedParteyIDs);
    }
  };

  const openPartey = (idNum) => {
    setActivePartey([]);
    setActiveParteyActions([]);
    setParteyActions([]);
    setDate("");
    setPlace("");
    setGuests([]);
    navigate("/" + idNum);
  };

  const addNewPartyToServer = async () => {
    const newParteyAction = {
      partey_id: "30",
      createdAt: "1726653701600",
      actionString: "addGuest",
      actionGuestIndexTarget: "1",
      actionGuestProductInternalID: "",
      actionGuestNewName: "",
    };
    setIsLoading(true);
    let dateNow = Date.now();
    let dateNowString = dateNow.toString();
    const resa = await fetch(`${apiURL}/api/parteys/index.php`, {
      method: "PUT",
      headers: {
        //   Authorization: `Bearer ${authToken?.JWT}`,
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify({
        createdAt: dateNowString,
      }),
    });

    await resa.text().then((value) => uploadEnds(value));
  };

  const uploadEnds = async (value) => {
    addSavedIDTOLocalStorage(value);
    let dateNow = Date.now();
    let dateNowString = dateNow.toString();
    const resa0 = await fetch(`${apiURL}/api/parteyActions/index.php`, {
      method: "PUT",
      headers: {
        //   Authorization: `Bearer ${authToken?.JWT}`,
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify({
        partey_id: value,
        createdAt: dateNow + 1,
        actionString: "addGuest",
        actionGuestIndexTarget: "0",
        actionGuestProductInternalID: "",
        actionGuestNewName: "Cheffe",
      }),
    });

    const resa1 = await fetch(`${apiURL}/api/parteyActions/index.php`, {
      method: "PUT",
      headers: {
        //   Authorization: `Bearer ${authToken?.JWT}`,
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify({
        partey_id: value,
        createdAt: dateNow + 2,
        actionString: "addGuest",
        actionGuestIndexTarget: "1",
        actionGuestProductInternalID: "",
        actionGuestNewName: "Cheffe",
      }),
    });

    const resa2 = await fetch(`${apiURL}/api/parteyActions/index.php`, {
      method: "PUT",
      headers: {
        //   Authorization: `Bearer ${authToken?.JWT}`,
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify({
        partey_id: value,
        createdAt: dateNow + 3,
        actionString: "changePic",
        actionGuestIndexTarget: "0",
        actionGuestProductInternalID: "",
        actionGuestNewName:
          "https://www.grillchef.servist.de/api/images/placeholder/pics-10.png",
      }),
    });

    const resa3 = await fetch(`${apiURL}/api/parteyActions/index.php`, {
      method: "PUT",
      headers: {
        //   Authorization: `Bearer ${authToken?.JWT}`,
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify({
        partey_id: value,
        createdAt: dateNow + 4,
        actionString: "changeName",
        actionGuestIndexTarget: "0",
        actionGuestProductInternalID: "",
        actionGuestNewName: "Partyplaner",
      }),
    });

    await resa3.text().then((value) => setIsLoading(false));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const categories = [
    "Fleisch",
    "Saucen",
    "Butter",
    "Brot/Buns",
    "Grillequipment",
    "Getränke",
    "Gewürze/Rubs",
    "Gemüse",
    "Vegan/Vegetarisch",
    "Utensilien",
  ];

  const addToCart = (obj) => {
    let helpArr = [...cart];
    helpArr.push(obj);
    setCart(helpArr);
  };

  const countPrices = () => {
    let totalPrice = 0;
    cart.map((el) => {
      totalPrice = totalPrice + parseInt(el.verkaufspreis);
    });
    return totalPrice;
  };

  function convertToEuroCent(eurocentStr) {
    // Convert the string to an integer
    let eurocentInt = parseInt(eurocentStr, 10);

    // Calculate the euro and cent values
    let euro = Math.floor(eurocentInt / 100);
    let cent = eurocentInt % 100;

    // Return the values as an array (or you can return an object if preferred)
    return [euro, cent];
  }

  const scrollToElement = (id) => {
    const targetElement = document.getElementById(id);

    // Get the top position of the target element
    const elementPosition =
      targetElement.getBoundingClientRect().top + window.pageYOffset;

    // Subtract 100px from the top position
    const offsetPosition = elementPosition - 80;

    // Scroll to the calculated position
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  const getClosestElementInViewport = (ids) => {
    function isInViewport(element) {
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    }

    let closestElement = null;
    let minDistance = Infinity;

    ids.forEach((id) => {
      const element = document.getElementById(id);
      if (element && isInViewport(element)) {
        const distance = element.getBoundingClientRect().top;
        if (distance < minDistance) {
          minDistance = distance;
          closestElement = id;
        }
      }
    });

    return closestElement;
  };

  useEffect(() => {
    const handleScroll = () => {
      const closest = getClosestElementInViewport(categories);
      if (!isEmpty(closest)) {
        setClosestElement(closest);
      }
    };

    // Attach the event listener
    window.addEventListener("scroll", handleScroll);

    // Initial check in case some elements are already in viewport on mount
    handleScroll();

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [categories]);

  useEffect(() => {
    if (closestElement) {
      const activeElement = document.getElementById(closestElement + "nav");
      const scrollbar = scrollbarRef.current;

      if (activeElement && scrollbar) {
        const activeElementRect = activeElement.getBoundingClientRect();
        const scrollbarRect = scrollbar.getBoundingClientRect();

        // Check if the active element is not at the very left of the scrollbar's viewport
        if (activeElementRect.left !== scrollbarRect.left) {
          // Scroll the container to bring the active element to the left smoothly
          scrollbar.scrollTo({
            left: activeElement.offsetLeft - scrollbarRect.left,
            behavior: "smooth",
          });
        }
      }
    }

    let searchEl = document.getElementById("searchInput");
    if (!isEmpty(searchEl)) {
      searchEl.addEventListener("keydown", function (event) {
        if (event.key === "Enter") {
          event.preventDefault();
          this.blur(); // Close the keyboard
        }
      });
    }
  }, [closestElement]);

  const getFirstSubProduct = (TopProd) => {
    if (!isEmpty(products)) {
      // Use `find` to locate the first product that matches the condition
      const foundProduct = products.find(
        (product) => product.variationVonID === TopProd.internal_id
      );

      if (foundProduct) {
        console.log(foundProduct); // Logging the found product
        return foundProduct; // Return the found product
      }
    }

    // If no product is found or the products array is empty, return TopProd
    return TopProd;
  };

  const addOrRemoveAnObject = (addOrRemove, object, indexNumber) => {
    console.log(object);
    if (addOrRemove == "add") {
      let helpArr = [...guests];
      helpArr[indexNumber].orders.push(object);
      setGuests(helpArr);
    } else {
      let helpArr = [...guests];

      const index = helpArr[indexNumber].orders.findIndex(
        (element) => element === object
      );
      if (index !== -1) {
        helpArr[indexNumber].orders.splice(index, 1);
      }

      setGuests(helpArr);
    }
  };

  // ich brauch ne funktion, die die orders eines gastes gruppiert
  // und als gruppenobjekt zurüpckgibt nur zur ansichtsdarstellung
  // und dann muss ich add remove modifyen dass es auf guests.orders geht

  const getOrdersWithCount = (orders) => {
    let helpArr = [...orders];
    let helpArr2 = [];

    helpArr.map((item) => {
      let foundElement = null;
      for (let i = 0; i < helpArr2.length; i++) {
        if (helpArr2[i].id === item.id) {
          foundElement = helpArr2[i];
        }
      }

      if (!foundElement) {
        helpArr2.push({ product: item, count: 1, id: item.id });
      } else {
        helpArr2.map((el) => {
          if (el.id == item.id) {
            el.count = el.count + 1;
          }
        });
      }
    });

    return helpArr2;
  };

  const addNewGuest = () => {
    let helpArr = [...guests];
    let guestNumber = helpArr.length;
    helpArr.push({ name: "Gast " + guestNumber, orders: [] });
    setGuests(helpArr);
  };

  const openPlaceNav = () => {
    //
  };

  const openDateNav = () => {
    //
  };

  const isIOS = () => {
    return false;
  };

  const shareButtonClicked = () => {
    if (isIOS) {
      // open navigator
      // share url
    } else {
      // do windows stuff
      // share url
    }
  };

  // desktop
  if (!isMobile) {
    return (
      <div className="home_desktop">
        {searchActive ? (
          <div className="home_topnav_desktop">
            <div className="home_topnavcenteralign">
              <div className="home_topnav_searchbar">
                <input
                  className="home_topnav_searchbar_input"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  placeholder="Suche nach einem Produkt"
                  autocomplete={"off"}
                  type="text"
                  id={"searchInput"}
                />
                <div
                  className="home_topnav_searchbar_close"
                  onClick={() => setSearchActive(false)}>
                  <CloseIcon />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="home_topnav_desktop">
            <div className="home_topnavcenteralign">
              <div className="home_topnav_searchbox">
                <div
                  className="home_topnav_searchbox_btn"
                  onClick={() => setSearchActive(true)}>
                  <SearchIcon />
                </div>
              </div>
              <div className="home_topnav_scrollbar" ref={scrollbarRef}>
                {categories.map((category) => (
                  <div
                    key={category}
                    id={category + "nav"}
                    className={
                      closestElement === category
                        ? "home_topnav_scrollbar_filteroption_active"
                        : "home_topnav_scrollbar_filteroption"
                    }
                    onClick={() => scrollToElement(category)}>
                    {category}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        <div className="home_body_desktop">
          <div className="home_body_banner_desktop">
            <video
              key={"bannervideo"} // Add key prop here
              controls={false}
              className="home_body_banner_video"
              preload="metadata"
              id={"bannervideo"}
              autoPlay={true}
              playsInline={true}
              muted={false}
              loop={true}
              poster="https://www.cloud.grillchef.app/api/images/placeholder/grillpartyposter.jpg" // Add poster attribute here
            >
              <source
                key={"bannervideosrc"}
                src="https://www.cloud.grillchef.app/api/images/placeholder/grillparty.mp4"
                type={`video/mp4`}
              />
              Your browser does not support the video tag.
            </video>
            <div className="home_body_banner_overlay">
              <img
                src="../../logo-white.svg"
                className="home_body_banner_overlay_logo"
              />
              Deine perfekte Grillparty
            </div>
          </div>
          <div className="home_bodycenteralign">
            <div className="home_body_products">
              {guests.map((guest) => (
                <div className="">Gast</div>
              ))}
            </div>
          </div>
          <div className="home_sidebar">
            <div className="home_sidebar_sticky">
              <Cart
                isMobile={isMobile}
                apiURL={apiURL}
                authToken={authToken}
                products={products}
                cart={cart}
                setCart={setCart}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  // mobile
  return (
    <div className="home">
      <div className="home_topnav">
        <div className="home_topnav_left">Partey.app</div>
      </div>
      <div className="home_sec1">
        <img
          src="https://www.grillchef.servist.de/api/images/placeholder/ichmachpartey.jpg"
          className=""
          style={{ width: "100%" }}
        />
      </div>
      <div className="home_parteyslist">
        {isLoading ? (
          <div className="home_parteyslist_new">
            <Spinner type="white" />
          </div>
        ) : (
          <div
            className="home_parteyslist_new"
            onClick={() => addNewPartyToServer()}>
            Neue Party planen
          </div>
        )}
        {parteys.map((partey, index) => (
          <div
            className="home_parteyslist_element"
            onClick={() => openPartey(partey.id)}>
            Partey Nummer {partey.id}
          </div>
        ))}
      </div>
    </div>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 30px;
  color: #474747;
`;

const CloseIcon = styled(MdClose)`
  font-size: 25px;
  color: #222222;
`;

const SearchIcon = styled(MdSearch)`
  font-size: 25px;
  color: #474747;
`;

const ArrowForwardIcon = styled(MdArrowForwardIos)`
  font-size: 18px;
  color: #474747;
`;
