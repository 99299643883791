import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowForwardIos,
  MdBookmark,
  MdBookmarkBorder,
  MdClose,
  MdGroup,
  MdMenu,
  MdOutlineShoppingCart,
  MdPerson,
  MdPersonOutline,
  MdSearch,
  MdShoppingCart,
} from "react-icons/md";

import "../styles/Home.css";
import "../styles/Guests.css";
import { isEmpty } from "../functions/isEmpty";
import { useLocation, useNavigate, useParams } from "react-router";
import { useRef } from "react";
import Cart from "./Cart";

export default function Partey({
  isMobile,
  apiURL,
  authToken,
  products,
  cart,
  setCart,
  searchInput,
  setSearchInput,
  scrollPosition,
  setScrollPosition,
  guests,
  setGuests,
  parteys,
  activePartey,
  setActivePartey,
  uneditedParteyState,
  setUneditedParteyState,
  activeParteyActions,
  setActiveParteyActions,
  date,
  setDate,
  place,
  setPlace,
  pics,
  savedParteyIDs,
  setSavedParteyIDs,
  setReloadFilteredParteys,
  reloadFilteredParteys,
  parteyActions,
  setParteyActions,
}) {
  const { parteyid } = useParams();

  let navigate = useNavigate();
  let location = useLocation().pathname;
  const [closestElement, setClosestElement] = useState(null);
  const scrollbarRef = useRef(null);
  const [searchActive, setSearchActive] = useState(false);
  const [changeName, setChangeName] = useState("");
  const [changeDay, setChangeDay] = useState("14");
  const [changeMonth, setChangeMonth] = useState("März");
  const [changeYear, setChangeYear] = useState("2025");
  const [changeHour, setChangeHour] = useState("18");
  const [changeMinutes, setChangeMinutes] = useState("00");
  const [changePlace, setChangePlace] = useState("");
  const [editableIndex, setEditableIndex] = useState("");
  const [editablePlace, setEditablePlace] = useState(false);
  const [editableDate, setEditableDate] = useState(false);
  const [editablePic, setEditablePic] = useState(false);

  useEffect(() => {
    if (savedParteyIDs != null && !savedParteyIDs.includes(parteyid)) {
      let helpArr = [...savedParteyIDs];
      helpArr.push(parteyid);
      setSavedParteyIDs(helpArr);
      setReloadFilteredParteys(!reloadFilteredParteys);
      localStorage.setItem("@parteyIDs", JSON.stringify(helpArr));
      console.log("saved");
    } else {
      console.log(savedParteyIDs);
    }
  }, [savedParteyIDs]);

  // mein lokaler state wird gespeichert in saveLocal
  // wenn saveLocal != activePartey , dann wird ein post gemacht
  // wenn t>10sec dann wird ein neuer fetch der daten gemacht
  useEffect(async () => {
    if (!isEmpty(parteyid)) {
      parteys.map((parte) => {
        if (
          parte.id == parteyid.toString() &&
          activePartey.id != parteyid.toString()
        ) {
          setActivePartey(parte);
        }
      });
    }
    if (!isEmpty(parteyActions) && parteyActions[0].partey_id != parteyid) {
      setParteyActions([]);
    }
  }, [parteyid, activePartey, parteyActions, date, place, parteys]);

  useEffect(() => {
    if (!isEmpty(place)) {
      setChangePlace(place);
    }
    if (!isEmpty(date)) {
      const timestamp = parseInt(date, 10);
      const dater = new Date(timestamp);
      const day = String(dater.getUTCDate()).padStart(2, "0"); // Ensure two digits, UTC
      const month = String(dater.getUTCMonth()); // Months are 0-based, UTC
      const year = dater.getUTCFullYear(); // Full year, UTC
      const hours = String(dater.getUTCHours()).padStart(2, "0"); // Ensure two digits, UTC
      const minutes = String(dater.getUTCMinutes()).padStart(2, "0"); // Ensure two digits, UTC

      const monthss = [
        "Januar",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember",
      ];
      setChangeDay(day);
      setChangeMonth(monthss[month]);
      setChangeYear(year);
      setChangeHour(hours);
      setChangeMinutes(minutes);
    }
  }, [date, place]);

  const formatUnixTimestamp = (timestampStr) => {
    // Parse the timestamp string to an integer
    const timestamp = parseInt(timestampStr, 10);

    // Create a new Date object from the Unix timestamp in milliseconds
    const date = new Date(timestamp);

    // Extract day, month, year, hours, and minutes
    const day = String(date.getUTCDate()).padStart(2, "0"); // Ensure two digits, UTC
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-based, UTC
    const year = date.getUTCFullYear(); // Full year, UTC
    const hours = String(date.getUTCHours()).padStart(2, "0"); // Ensure two digits, UTC
    const minutes = String(date.getUTCMinutes()).padStart(2, "0"); // Ensure two digits, UTC

    // Format date as dd.mm.yyyy hh:mm
    if (isEmpty(timestampStr)) {
      console.log(day + month + year + hours + minutes);
      return "Datum auswählen";
    } else {
      return `${day}.${month}.${year} ${hours}:${minutes}`;
    }
  };

  const initializeParteyStates = (value) => {
    setActivePartey(value);
    setUneditedParteyState(value);
  };

  // Function to save the current scroll position
  const handleNavigateClick = (destination, object) => {
    console.log(window.scrollY);
    setScrollPosition(window.scrollY);
    navigate(destination);
  };

  // Effect to scroll to the saved position on component mount
  useEffect(() => {
    if (editablePic) {
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, scrollPosition);
    }
  }, [scrollPosition, editablePic]);

  const categories = [
    "Fleisch",
    "Saucen",
    "Butter",
    "Brot/Buns",
    "Grillequipment",
    "Getränke",
    "Gewürze/Rubs",
    "Gemüse",
    "Vegan/Vegetarisch",
    "Utensilien",
  ];

  const addToCart = (obj) => {
    let helpArr = [...cart];
    helpArr.push(obj);
    setCart(helpArr);
  };

  const countPrices = () => {
    let totalPrice = 0;
    cart.map((el) => {
      totalPrice = totalPrice + parseInt(el.verkaufspreis);
    });
    return totalPrice;
  };

  function convertToEuroCent(eurocentStr) {
    // Convert the string to an integer
    let eurocentInt = parseInt(eurocentStr, 10);

    // Calculate the euro and cent values
    let euro = Math.floor(eurocentInt / 100);
    let cent = eurocentInt % 100;

    // Return the values as an array (or you can return an object if preferred)
    return [euro, cent];
  }

  const scrollToElement = (id) => {
    const targetElement = document.getElementById(id);

    // Get the top position of the target element
    const elementPosition =
      targetElement.getBoundingClientRect().top + window.pageYOffset;

    // Subtract 100px from the top position
    const offsetPosition = elementPosition - 80;

    // Scroll to the calculated position
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  const getClosestElementInViewport = (ids) => {
    function isInViewport(element) {
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    }

    let closestElement = null;
    let minDistance = Infinity;

    ids.forEach((id) => {
      const element = document.getElementById(id);
      if (element && isInViewport(element)) {
        const distance = element.getBoundingClientRect().top;
        if (distance < minDistance) {
          minDistance = distance;
          closestElement = id;
        }
      }
    });

    return closestElement;
  };

  useEffect(() => {
    const handleScroll = () => {
      const closest = getClosestElementInViewport(categories);
      if (!isEmpty(closest)) {
        setClosestElement(closest);
      }
    };

    // Attach the event listener
    window.addEventListener("scroll", handleScroll);

    // Initial check in case some elements are already in viewport on mount
    handleScroll();

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [categories]);

  useEffect(() => {
    if (closestElement) {
      const activeElement = document.getElementById(closestElement + "nav");
      const scrollbar = scrollbarRef.current;

      if (activeElement && scrollbar) {
        const activeElementRect = activeElement.getBoundingClientRect();
        const scrollbarRect = scrollbar.getBoundingClientRect();

        // Check if the active element is not at the very left of the scrollbar's viewport
        if (activeElementRect.left !== scrollbarRect.left) {
          // Scroll the container to bring the active element to the left smoothly
          scrollbar.scrollTo({
            left: activeElement.offsetLeft - scrollbarRect.left,
            behavior: "smooth",
          });
        }
      }
    }

    let searchEl = document.getElementById("searchInput");
    if (!isEmpty(searchEl)) {
      searchEl.addEventListener("keydown", function (event) {
        if (event.key === "Enter") {
          event.preventDefault();
          this.blur(); // Close the keyboard
        }
      });
    }
  }, [closestElement]);

  const getFirstSubProduct = (TopProd) => {
    if (!isEmpty(products)) {
      // Use `find` to locate the first product that matches the condition
      const foundProduct = products.find(
        (product) => product.variationVonID === TopProd.internal_id
      );

      if (foundProduct) {
        console.log(foundProduct); // Logging the found product
        return foundProduct; // Return the found product
      }
    }

    // If no product is found or the products array is empty, return TopProd
    return TopProd;
  };

  const addOrRemoveAnObject = (addOrRemove, object, indexNumber) => {
    console.log(object);
    if (addOrRemove == "add") {
      let helpArr = [...guests];
      helpArr[indexNumber].orders.push(object);
      setGuests(helpArr);
    } else {
      let helpArr = [...guests];

      const index = helpArr[indexNumber].orders.findIndex(
        (element) => element === object
      );
      if (index !== -1) {
        helpArr[indexNumber].orders.splice(index, 1);
      }

      setGuests(helpArr);
    }
  };

  // ich brauch ne funktion, die die orders eines gastes gruppiert
  // und als gruppenobjekt zurüpckgibt nur zur ansichtsdarstellung
  // und dann muss ich add remove modifyen dass es auf guests.orders geht

  const getOrdersWithCount = (orders) => {
    let helpArr = [...orders];
    let helpArr2 = [];

    helpArr.map((item) => {
      let foundElement = null;
      for (let i = 0; i < helpArr2.length; i++) {
        if (helpArr2[i].id === item.id) {
          foundElement = helpArr2[i];
        }
      }

      if (!foundElement) {
        helpArr2.push({ product: item, count: 1, id: item.id });
      } else {
        helpArr2.map((el) => {
          if (el.id == item.id) {
            el.count = el.count + 1;
          }
        });
      }
    });

    return helpArr2;
  };

  const addNewGuest = () => {
    let helpArr2 = [...activeParteyActions];
    let dateNow = Date.now();
    let dateNowString = dateNow.toString();
    helpArr2.push({
      partey_id: parteyid,
      createdAt: dateNowString,
      actionString: "addGuest",
      actionGuestIndexTarget: (guests.length + 1).toString(),
    });
    setActiveParteyActions(helpArr2);
  };

  const openPlaceNav = () => {
    //
  };

  const openDateNav = () => {
    //
  };

  const isIOS = () => {
    return false;
  };

  const shareButtonClicked = () => {
    const text = "Bist du bei meiner Grillpartey dabei?";
    const url = "https://partey.app/" + parteyid;
    if (navigator.share) {
      // Use the Web Share API
      navigator
        .share({
          title: "Plant mit mir eine Grillparty",
          text: text,
          url: url,
        })
        .then(() => {
          console.log("Successfully shared");
        })
        .catch((error) => {
          console.error("Something went wrong sharing:", error);
        });
    } else {
      // Fallback for browsers that don't support Navigator.share
      fallbackShare(url, text);
    }
  };

  const fallbackShare = (url, text) => {
    // Fallback: for browsers without `navigator.share`, you can copy the URL to the clipboard, prompt, etc.
    const shareText = `${text}\n${url}`;
    if (navigator.clipboard) {
      navigator.clipboard.writeText(shareText).then(
        () => {
          alert("Link copied to clipboard!");
        },
        (error) => {
          console.error("Could not copy text:", error);
        }
      );
    } else {
      // As a last resort, prompt the user to copy the text manually
      prompt("Copy the link:", shareText);
    }
  };

  const getCategoryByID = (id) => {
    const product = products.find((product) => product.internal_id == id);
    return product ? product.category : null;
  };

  const getProductByID = (id) => {
    const product = products.find((product) => product.internal_id == id);
    return product ? product : null;
  };

  const countItemsInCategory = (guestIndex, targetCategory) => {
    if (isEmpty(activeParteyActions)) {
      return 0;
    }
    let count = 0;
    let productsArray = [];
    activeParteyActions.map((action) => {
      if (
        action.actionString == "AddProductToGuest" &&
        action.actionGuestIndexTarget == guestIndex
      ) {
        if (
          targetCategory == getCategoryByID(action.actionGuestProductInternalID)
        ) {
          productsArray.push(action.actionGuestProductInternalID);
          count++;
        }
      }

      if (
        action.actionString == "RemoveProductFromGuest" &&
        action.actionGuestIndexTarget == guestIndex
      ) {
        if (
          targetCategory == getCategoryByID(action.actionGuestProductInternalID)
        ) {
          if (productsArray.includes(action.actionGuestProductInternalID)) {
            count--;
          }
        }
      }
    });

    return count;
  };

  const countObjectByValue = (array, targetObject) => {
    if (isEmpty(array)) {
      return 0;
    }
    const isObjectEqual = (obj1, obj2) => {
      return JSON.stringify(obj1) === JSON.stringify(obj2);
    };

    return array.reduce((count, item) => {
      if (isObjectEqual(item, targetObject.internal_id)) {
        return count + 1;
      }
      return count;
    }, 0);
  };

  const getName = (index) => {
    if (!isEmpty(activeParteyActions)) {
      let name = "";
      let lastCreatedAt = 0;
      activeParteyActions.map((action) => {
        if (
          action.actionString == "changeName" &&
          action.actionGuestIndexTarget == index
        ) {
          if (lastCreatedAt < parseInt(action.createdAt))
            name = action.actionGuestNewName;
          lastCreatedAt = parseInt(action.createdAt);
        }
      });
      if (name == "") {
        return "Neuer Gast";
      } else {
        return name;
      }
    }
  };

  const handleSaveChangeName = (indexTarget) => {
    let helpArr2 = [...activeParteyActions];
    let dateNow = Date.now();
    let dateNowString = dateNow.toString();
    let NewObject = {
      partey_id: parteyid,
      createdAt: dateNowString,
      actionString: "changeName",
      actionGuestIndexTarget: indexTarget,
      actionGuestNewName: changeName,
    };
    helpArr2.push(NewObject);
    setActiveParteyActions(helpArr2);
    setChangeName("");
    setEditableIndex("");
  };

  function toUnixTimestamp(day, monthStr, year, hour, minute) {
    // Map of month names to numbers (zero-indexed)
    const monthss = {
      Januar: 0,
      Februar: 1,
      März: 2,
      April: 3,
      Mai: 4,
      Juni: 5,
      Juli: 6,
      August: 7,
      September: 8,
      Oktober: 9,
      November: 10,
      Dezember: 11,
    };

    // Convert day, year, hour, minute from strings to integers
    const dayNum = parseInt(day);
    const yearNum = parseInt(year);
    const hourNum = parseInt(hour);
    const minuteNum = parseInt(minute);

    // Get the month number from the month name
    const monthNum = monthss[monthStr]; // This will give 2 for "März" (March)

    if (monthNum === undefined) {
      throw new Error("Invalid month name provided");
    }

    // Create a Date object in UTC
    const date = new Date(
      Date.UTC(yearNum, monthNum, dayNum, hourNum, minuteNum, 0)
    ); // Use UTC

    // Get the Unix timestamp in milliseconds (already UTC)
    return date.getTime().toString();
  }

  const handleSaveChangeDate = () => {
    let helpArr2 = [...activeParteyActions];
    let dateNow = Date.now();
    let dateNowString = dateNow.toString();
    let changeDateString = toUnixTimestamp(
      changeDay,
      changeMonth,
      changeYear,
      changeHour,
      changeMinutes
    );
    let NewObject = {
      partey_id: parteyid,
      createdAt: dateNowString,
      actionString: "changeDate",
      actionGuestNewName: changeDateString,
    };
    helpArr2.push(NewObject);
    setDate(changeDateString);
    setActiveParteyActions(helpArr2);
    setEditableDate(false);
  };

  const handleSaveChangePlace = () => {
    let helpArr2 = [...activeParteyActions];
    let dateNow = Date.now();
    let dateNowString = dateNow.toString();
    let NewObject = {
      partey_id: parteyid,
      createdAt: dateNowString,
      actionString: "changePlace",
      actionGuestNewName: changePlace,
    };
    helpArr2.push(NewObject);
    setPlace(changePlace);
    setActiveParteyActions(helpArr2);
    setChangePlace("");
    setEditablePlace(false);
  };

  const days = () => {
    let monthsWith31 = [
      "Januar",
      "März",
      "Mai",
      "Juli",
      "August",
      "Oktober",
      "Dezember",
    ];
    let array = [];
    let limit = 30;
    if (monthsWith31.includes(changeMonth)) {
      limit = 31;
    }
    if (changeMonth == "Februar") {
      limit = 28;
    }
    for (let i = 1; i <= limit; i++) {
      // Start from 1 and go to 31
      array.push(i);
    }
    return array;
  };

  const months = [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ];

  const years = ["2024", "2025", "2026", "2027"];

  const hours = () => {
    let array = [];
    let limit = 24;
    for (let i = 1; i <= limit; i++) {
      // Start from 1 and go to 31
      array.push(i);
    }
    return array;
  };
  const minutes = ["15", "30", "45", "00"];

  const openPics = (index) => {
    setScrollPosition(window.scrollY);
    setEditableIndex(index);
    setEditablePic(true);
  };

  const closePics = () => {
    setEditableIndex("");
    setEditablePic(false);
  };

  const pickThisPic = (picURL) => {
    let helpArr2 = [...activeParteyActions];
    let dateNow = Date.now();
    let dateNowString = dateNow.toString();
    let NewObject = {
      partey_id: parteyid,
      createdAt: dateNowString,
      actionString: "changePic",
      actionGuestIndexTarget: editableIndex,
      actionGuestNewName: picURL,
    };
    helpArr2.push(NewObject);
    setActiveParteyActions(helpArr2);
    setEditableIndex("");
    setEditablePic(false);
  };

  if (editablePic) {
    return (
      <div className="home">
        <div className="home_topnav">
          <div className="home_topnav_left" onClick={() => closePics()}>
            {"<"} Bild auswählen
          </div>
        </div>
        <div className="home_pics">
          {pics.map((pic) => (
            <div className="home_pics_pic" onClick={() => pickThisPic(pic)}>
              <img src={pic} className="home_pics_pic_img" />
            </div>
          ))}
        </div>
      </div>
    );
  }

  const getPicByIndex = (index) => {
    let url =
      "https://www.cloud.leefes.com/api/images/placeholder/profilbild.svg";
    let lastCreatedAt = 0;
    activeParteyActions.map((action) => {
      if (
        action.actionString == "changePic" &&
        action.actionGuestIndexTarget == index &&
        lastCreatedAt < action.createdAt
      ) {
        url = action.actionGuestNewName;
        lastCreatedAt = action.createdAt;
      }
    });

    if (isEmpty(url)) {
      return "https://www.cloud.leefes.com/api/images/placeholder/profilbild.svg";
    }
    return url;
  };

  // mobile
  return (
    <div className="home">
      {searchActive ? (
        <div className="home_topnav">
          <div className="home_topnav_searchbar">
            <input
              className="home_topnav_searchbar_input"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder="Suche nach einem Produkt"
              autocomplete={"off"}
              type="text"
              id={"searchInput"}
            />
            <div
              className="home_topnav_searchbar_close"
              onClick={() => setSearchActive(false)}>
              <CloseIcon />
            </div>
          </div>
        </div>
      ) : (
        <div className="home_topnav">
          <div
            className="home_topnav_left"
            onClick={() => handleNavigateClick("/")}>
            Grill Partey
          </div>
          <div
            className="home_topnav_right"
            onClick={() => shareButtonClicked()}>
            <FriendsIcon /> Freunde mitplanen lassen
          </div>
        </div>
      )}

      <div className="home_body">
        <div className="home_body_products">
          <div className="home_dates">
            <div className="">
              {editableDate ? (
                <>
                  <select
                    className=""
                    onChange={(e) => setChangeDay(e.target.value)}>
                    {days().map((day) => (
                      <option
                        value={day}
                        selected={day == changeDay ? true : false}>
                        {day}
                      </option>
                    ))}
                  </select>

                  <select
                    className=""
                    onChange={(e) => setChangeMonth(e.target.value)}>
                    {months.map((month) => (
                      <option
                        value={month}
                        selected={month == changeMonth ? true : false}>
                        {month}
                      </option>
                    ))}
                  </select>

                  <select
                    className=""
                    onChange={(e) => setChangeYear(e.target.value)}>
                    {years.map((year) => (
                      <option
                        value={year}
                        selected={year == changeYear ? true : false}>
                        {year}
                      </option>
                    ))}
                  </select>

                  <select
                    className=""
                    onChange={(e) => setChangeHour(e.target.value)}>
                    {hours().map((hour) => (
                      <option
                        value={hour}
                        selected={hour == changeHour ? true : false}>
                        {hour}
                      </option>
                    ))}
                  </select>

                  <select
                    className=""
                    onChange={(e) => setChangeMinutes(e.target.value)}>
                    {minutes.map((minute) => (
                      <option
                        value={minute}
                        selected={minute == changeMinutes ? true : false}>
                        {minute}
                      </option>
                    ))}
                  </select>

                  <div
                    className="guests_element_personrow_name_savebtn"
                    onClick={() => handleSaveChangeDate()}>
                    Speichern
                  </div>
                </>
              ) : (
                <div
                  className="home_dates_place"
                  onClick={() => setEditableDate(true)}>
                  Wann : {!isEmpty(activePartey) && formatUnixTimestamp(date)}
                </div>
              )}
            </div>
            <div>
              {editablePlace ? (
                <>
                  <input
                    type="text"
                    placeholder="Name eingeben"
                    className="home_dates_place"
                    value={changePlace}
                    onChange={(e) => setChangePlace(e.target.value)}
                  />
                  <div
                    className="guests_element_personrow_name_savebtn"
                    onClick={() => handleSaveChangePlace()}>
                    Speichern
                  </div>
                </>
              ) : (
                <div
                  className="home_dates_place"
                  onClick={() => setEditablePlace(true)}>
                  Wo :{" "}
                  {!isEmpty(activePartey) && isEmpty(place)
                    ? "Treffpunkt auswählen"
                    : place}
                </div>
              )}
            </div>
          </div>
          <div className="guests">
            {!isEmpty(guests) &&
              guests.map((guest, index) => (
                <div className="guests_element" key={"gast " + index}>
                  <div className="guests_element_personrow">
                    <div
                      className="guests_element_personrow_imgbox"
                      onClick={() => openPics(index)}>
                      <img
                        src={getPicByIndex(index)}
                        className="guests_element_personrow_imgbox_img"
                      />
                    </div>
                    <div className="guests_element_personrow_name">
                      {editableIndex === index ? (
                        <>
                          <input
                            type="text"
                            placeholder="Name eingeben"
                            className="guests_element_personrow_name_input"
                            value={changeName}
                            onChange={(e) => setChangeName(e.target.value)}
                          />
                          <div
                            className="guests_element_personrow_name_savebtn"
                            onClick={() => handleSaveChangeName(index)}>
                            Speichern
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className="guests_element_personrow_name_input"
                            onClick={() => setEditableIndex(index)}>
                            {!isEmpty(activeParteyActions) && getName(index)}
                          </div>
                          <div
                            className="guests_element_personrow_name_right"
                            onClick={() =>
                              handleNavigateClick(
                                "/" + parteyid + "/guest/" + index
                              )
                            }>
                            a
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div
                    className="guests_element_foodrow"
                    onClick={() =>
                      handleNavigateClick("/" + parteyid + "/guest/" + index)
                    }>
                    <div className="guests_element_foodrow_left">
                      <div className="guests_element_foodrow_left_element">
                        <img
                          src="https://www.grillchef.servist.de/api/images/placeholder/steak.png"
                          className="guests_element_foodrow_left_element_img"
                        />
                        {!isEmpty(activePartey) &&
                          countItemsInCategory(index, "Steaks")}{" "}
                        Steaks
                      </div>
                      <div className="guests_element_foodrow_left_element">
                        <img
                          src="https://www.grillchef.servist.de/api/images/placeholder/wuerstchen.png"
                          className="guests_element_foodrow_left_element_img"
                        />
                        {!isEmpty(activePartey) &&
                          countItemsInCategory(index, "Wuerstchen")}{" "}
                        Würstchen
                      </div>
                      <div className="guests_element_foodrow_left_element">
                        <img
                          src="https://www.grillchef.servist.de/api/images/placeholder/brot.png"
                          className="guests_element_foodrow_left_element_img"
                        />
                        {!isEmpty(activePartey) &&
                          countItemsInCategory(index, "Beilagen")}{" "}
                        Beilagen
                      </div>
                      <div className="guests_element_foodrow_left_element">
                        <img
                          src="https://www.grillchef.servist.de/api/images/placeholder/getraenke.png"
                          className="guests_element_foodrow_left_element_img"
                        />
                        {!isEmpty(activePartey) &&
                          countItemsInCategory(index, "Getränke")}{" "}
                        Getränke
                      </div>
                    </div>
                    <div
                      className="guests_element_foodrow_right"
                      onClick={() => handleNavigateClick("/guest/" + index)}>
                      <ArrowForwardIcon />
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className="guest_add" onClick={() => addNewGuest()}>
            Weiteren Gast hinzufügen
          </div>
          <div
            className="guest_pay"
            onClick={() => handleNavigateClick("/cart")}>
            Essen liefern lassen (in 24h)
          </div>
        </div>
      </div>
    </div>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 30px;
  color: #474747;
`;

const CloseIcon = styled(MdClose)`
  font-size: 25px;
  color: #222222;
`;

const SearchIcon = styled(MdSearch)`
  font-size: 25px;
  color: #474747;
`;

const FriendsIcon = styled(MdGroup)`
  font-size: 25px;
  color: #0077ff;
`;

const ArrowForwardIcon = styled(MdArrowForwardIos)`
  font-size: 18px;
  color: #474747;
`;
