import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowForwardIos,
  MdBookmark,
  MdBookmarkBorder,
  MdCheckCircle,
  MdClose,
  MdMenu,
  MdOutlineShoppingCart,
  MdPerson,
  MdPersonOutline,
  MdSearch,
  MdShoppingCart,
} from "react-icons/md";

import "../styles/Home.css";
import { isEmpty } from "../functions/isEmpty";
import { useLocation, useNavigate, useParams } from "react-router";
import { useRef } from "react";
import Cart from "./Cart";

export default function ProductList({
  isMobile,
  apiURL,
  authToken,
  cart,
  setCart,
  searchInput,
  setSearchInput,
  scrollPosition,
  setScrollPosition,
  guests,
  setGuests,
  products,
  activePartey,
  setActivePartey,
  uneditedParteyState,
  setUneditedParteyState,
  parteys,
  activeParteyActions,
  setActiveParteyActions,
  savedParteyIDs,
  setSavedParteyIDs,
}) {
  const { parteyid, id } = useParams();
  let navigate = useNavigate();
  let location = useLocation().pathname;
  const [closestElement, setClosestElement] = useState(null);
  const scrollbarRef = useRef(null);
  const [searchActive, setSearchActive] = useState(false);
  const [guestName, setGuestName] = useState("");

  useEffect(() => {
    if (!isEmpty(activeParteyActions) && !isEmpty(id)) {
      let name = "";
      let lastCreatedAt = 0;
      activeParteyActions.map((action) => {
        if (
          action.actionString == "changeName" &&
          action.actionGuestIndexTarget == id
        ) {
          if (lastCreatedAt < parseInt(action.createdAt))
            name = action.actionGuestNewName;
          lastCreatedAt = parseInt(action.createdAt);
        }
      });
      setGuestName(name);
    }
  }, [activeParteyActions, id]);

  useEffect(async () => {
    if (!isEmpty(parteyid)) {
      parteys.map((parte) => {
        if (
          parte.id == parteyid.toString() &&
          activePartey.id != parteyid.toString()
        ) {
          setActivePartey(parte);
        }
      });
    }
  }, [parteyid, parteys]);

  // Effect to scroll to the saved position on component mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const categories = ["Steaks", "Wuerstchen", "Getränke", "Beilagen"];

  const addToCart = (obj) => {
    let helpArr = [...cart];
    let helpArr2 = [...guests];
    helpArr.push(obj);
    setCart(helpArr);

    helpArr2[id].orders.push(obj);
    setGuests(helpArr2);
  };

  const countPrices = () => {
    let totalPrice = 0;
    cart.map((el) => {
      totalPrice = totalPrice + parseInt(el.verkaufspreis);
    });
    return totalPrice;
  };

  function convertToEuroCent(eurocentStr) {
    // Convert the string to an integer
    let eurocentInt = parseInt(eurocentStr, 10);

    // Calculate the euro and cent values
    let euro = Math.floor(eurocentInt / 100);
    let cent = eurocentInt % 100;

    // Return the values as an array (or you can return an object if preferred)
    return [euro, cent];
  }

  const scrollToElement = (id) => {
    const targetElement = document.getElementById(id);

    // Get the top position of the target element
    const elementPosition =
      targetElement.getBoundingClientRect().top + window.pageYOffset;

    // Subtract 100px from the top position
    const offsetPosition = elementPosition - 80;

    // Scroll to the calculated position
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  const getClosestElementInViewport = (ids) => {
    function isInViewport(element) {
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    }

    let closestElement = null;
    let minDistance = Infinity;

    ids.forEach((id) => {
      const element = document.getElementById(id);
      if (element && isInViewport(element)) {
        const distance = element.getBoundingClientRect().top;
        if (distance < minDistance) {
          minDistance = distance;
          closestElement = id;
        }
      }
    });

    return closestElement;
  };

  useEffect(() => {
    const handleScroll = () => {
      const closest = getClosestElementInViewport(categories);
      if (!isEmpty(closest)) {
        setClosestElement(closest);
      }
    };

    // Attach the event listener
    window.addEventListener("scroll", handleScroll);

    // Initial check in case some elements are already in viewport on mount
    handleScroll();

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [categories]);

  useEffect(() => {
    if (closestElement) {
      const activeElement = document.getElementById(closestElement + "nav");
      const scrollbar = scrollbarRef.current;

      if (activeElement && scrollbar) {
        const activeElementRect = activeElement.getBoundingClientRect();
        const scrollbarRect = scrollbar.getBoundingClientRect();

        // Check if the active element is not at the very left of the scrollbar's viewport
        if (activeElementRect.left !== scrollbarRect.left) {
          // Scroll the container to bring the active element to the left smoothly
          scrollbar.scrollTo({
            left: activeElement.offsetLeft - scrollbarRect.left,
            behavior: "smooth",
          });
        }
      }
    }

    let searchEl = document.getElementById("searchInput");
    if (!isEmpty(searchEl)) {
      searchEl.addEventListener("keydown", function (event) {
        if (event.key === "Enter") {
          event.preventDefault();
          this.blur(); // Close the keyboard
        }
      });
    }
  }, [closestElement]);

  const getFirstSubProduct = (TopProd) => {
    if (!isEmpty(products)) {
      // Use `find` to locate the first product that matches the condition
      const foundProduct = products.find(
        (product) => product.variationVonID === TopProd.internal_id
      );

      if (foundProduct) {
        console.log(foundProduct); // Logging the found product
        return foundProduct; // Return the found product
      }
    }

    // If no product is found or the products array is empty, return TopProd
    return TopProd;
  };

  const categoryURLS = [
    "steak.png",
    "wuerstchen.png",
    "getraenke.png",
    "brot.png",
  ];

  const increaseDecreaseProduct = (incOrDec, product, guestIndex) => {
    if (incOrDec == "increase") {
      // mein lokaler state wird gespeichert in saveLocal
      // wenn saveLocal != activePartey , dann wird ein post gemacht
      // wenn t>10sec dann wird ein neuer fetch der daten gemacht

      let helpArr2 = [...activeParteyActions];
      let dateNow = Date.now();
      let dateNowString = dateNow.toString();
      helpArr2.push({
        id: "1",
        partey_id: parteyid,
        createdAt: dateNowString,
        actionString: "AddProductToGuest",
        actionGuestIndexTarget: guestIndex.toString(),
        actionGuestProductInternalID: product.internal_id,
      });

      setActiveParteyActions(helpArr2);
    } else {
      let helpArr2 = [...activeParteyActions];
      let dateNow = Date.now();
      let dateNowString = dateNow.toString();
      helpArr2.push({
        id: "1",
        partey_id: parteyid,
        createdAt: dateNowString,
        actionString: "RemoveProductFromGuest",
        actionGuestIndexTarget: guestIndex.toString(),
        actionGuestProductInternalID: product.internal_id,
      });

      setActiveParteyActions(helpArr2);
    }
  };

  const getCategoryByID = (id) => {
    const product = products.find((product) => product.internal_id == id);
    return product ? product.category : null;
  };

  const countItemsInCategory = (guestIndex, targetCategory) => {
    if (isEmpty(activeParteyActions)) {
      return 0;
    }
    let count = 0;
    let productsArray = [];
    activeParteyActions.map((action) => {
      if (
        action.actionString == "AddProductToGuest" &&
        action.actionGuestIndexTarget == guestIndex
      ) {
        if (
          targetCategory == getCategoryByID(action.actionGuestProductInternalID)
        ) {
          productsArray.push(action.actionGuestProductInternalID);
          count++;
        }
      }

      if (
        action.actionString == "RemoveProductFromGuest" &&
        action.actionGuestIndexTarget == guestIndex
      ) {
        if (
          targetCategory == getCategoryByID(action.actionGuestProductInternalID)
        ) {
          if (productsArray.includes(action.actionGuestProductInternalID)) {
            count--;
          }
        }
      }
    });

    return count;
  };

  const countObjectByValue = (guestIndex, targetObject) => {
    if (isEmpty(activeParteyActions)) {
      return 0;
    }

    const isObjectEqual = (obj1, obj2) => {
      return JSON.stringify(obj1) === JSON.stringify(obj2);
    };

    let count = 0;
    let productsArray = [];
    activeParteyActions.map((action) => {
      if (
        action.actionString == "AddProductToGuest" &&
        action.actionGuestIndexTarget == guestIndex
      ) {
        if (
          isObjectEqual(
            action.actionGuestProductInternalID,
            targetObject.internal_id
          )
        ) {
          productsArray.push(action.actionGuestProductInternalID);
          count++;
        }
      }

      if (
        action.actionString == "RemoveProductFromGuest" &&
        action.actionGuestIndexTarget == guestIndex
      ) {
        if (
          isObjectEqual(
            action.actionGuestProductInternalID,
            targetObject.internal_id
          )
        ) {
          if (productsArray.includes(action.actionGuestProductInternalID)) {
            count--;
          }
        }
      }
    });

    return count;
  };

  return (
    <div className="home">
      {searchActive ? (
        <div className="home_topnav">
          <div className="home_topnav_searchbar">
            <input
              className="home_topnav_searchbar_input"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder="Suche nach einem Produkt"
              autocomplete={"off"}
              type="text"
              id={"searchInput"}
            />
            <div
              className="home_topnav_searchbar_close"
              onClick={() => setSearchActive(false)}>
              <CloseIcon />
            </div>
          </div>
        </div>
      ) : (
        <div className="product_topnav">
          <div className="product_topnav_left">
            {isEmpty(guestName) ? "Neuer Gast" : guestName}
          </div>
          <div
            className="product_topnav_right"
            onClick={() => navigate("/" + parteyid)}>
            <CheckIcon />
          </div>
        </div>
      )}

      <div className="home_body">
        <div className="home_body_products">
          {searchActive ? (
            <>
              <div className="home_body_products_sectionTitle">
                Suchergebnisse für {searchInput}
              </div>
              {!isEmpty(products) &&
                searchInput.length > 2 &&
                products.map((product) => {
                  if (
                    product.titel
                      .toLowerCase()
                      .includes(searchInput.trim().toLowerCase())
                  ) {
                    return (
                      <div
                        className="home_body_products_product"
                        key={product.id}>
                        <div className="home_body_products_product_left">
                          <img
                            src={JSON.parse(product.bilder)[0].url}
                            className="home_body_products_product_left_img"
                          />
                        </div>
                        <div className="home_body_products_product_middle">
                          {product.titel}
                        </div>
                        <div className="home_body_products_product_right">
                          <div className="home_body_products_product_right_decrease"></div>
                          <div className="home_body_products_product_right_increase"></div>
                        </div>
                      </div>
                    );
                  }
                  return null; // Return null if the product title does not include the search input
                })}
            </>
          ) : (
            <>
              {categories.map((category, index) => {
                return (
                  <>
                    <div
                      className="home_body_products_sectionTitle"
                      id={category}>
                      <div className="home_body_products_sectionTitle_left">
                        <img
                          src={
                            "https://www.grillchef.servist.de/api/images/placeholder/" +
                            categoryURLS[index]
                          }
                          style={{
                            width: "40px",
                            height: "40px",
                            objectFit: "cover",
                          }}
                        />
                        {category}
                      </div>{" "}
                      <div className="home_body_products_sectionTitle_right">
                        {!isEmpty(activePartey) &&
                          countItemsInCategory(id, category)}
                      </div>
                    </div>
                    {products.map((product) => {
                      if (
                        product.category == category &&
                        isEmpty(product.variationVonID)
                      ) {
                        return (
                          <div className="home_body_products_product">
                            <div className="home_body_products_product_left">
                              <img
                                src={JSON.parse(product.bilder)[0].url}
                                className="home_body_products_product_left_img"
                              />
                            </div>
                            <div className="home_body_products_product_middle">
                              {product.titel}
                            </div>
                            <div className="home_body_products_product_right">
                              <div
                                className="home_body_products_product_right_decrease"
                                onClick={() =>
                                  increaseDecreaseProduct(
                                    "decrease",
                                    product,
                                    id
                                  )
                                }>
                                -
                              </div>
                              <div className="home_body_products_product_right_count">
                                {!isEmpty(activePartey) &&
                                  countObjectByValue(id, product)}
                              </div>
                              <div
                                className="home_body_products_product_right_increase"
                                onClick={() =>
                                  increaseDecreaseProduct(
                                    "increase",
                                    product,
                                    id
                                  )
                                }>
                                +
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </>
                );
              })}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 30px;
  color: #474747;
`;

const CloseIcon = styled(MdClose)`
  font-size: 25px;
  color: #222222;
`;

const CheckIcon = styled(MdCheckCircle)`
  font-size: 33px;
  color: #0077ff;
`;

const SearchIcon = styled(MdSearch)`
  font-size: 25px;
  color: #474747;
`;

const ArrowForwardIcon = styled(MdArrowForwardIos)`
  font-size: 18px;
  color: #474747;
`;
