import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  Navigate,
  useParams,
  useNavigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./styles/App.css";
import { isEmpty } from "./functions/isEmpty";
import useFetch from "./functions/useFetch";

import Home from "./screens/Home.js";
import * as authentication from "./functions/authentication";
import useScreenType from "./functions/useScreenType";
import { LanguageProvider } from "./contexts/LanguageContext";
import Product from "./screens/Product";
import Cart from "./screens/Cart";
import Checkout from "./screens/Checkout";
import ProductList from "./screens/ProductList";
import Partey from "./screens/Partey.js";

const developerMode = true;

export default function App() {
  let apiURL = "https://cloud.grillchef.app";
  if (developerMode) {
    apiURL = "https://www.grillchef.servist.de";
  }

  /* Auth Token */
  const [authToken, setAuthToken] = useState("");

  /* userdata */
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");

  /* localStorage Data */
  const [savedParteyIDs, setSavedParteyIDs] = useState(null);
  const [filteredParteys, setFilteredParteys] = useState([]);

  /* orderdata */
  const [activePartey, setActivePartey] = useState([]);
  const [activeParteyActions, setActiveParteyActions] = useState([]);
  const [uneditedParteyState, setUneditedParteyState] = useState([]);
  const [debouncedPartey, setDebouncedPartey] = useState(null);
  const [guests, setGuests] = useState([]);
  const [place, setPlace] = useState("");
  const [date, setDate] = useState("");

  const [cart, setCart] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  /* handle Page */
  const [reload, setReload] = useState(false);
  const [reloadParteyActions, setReloadParteyActions] = useState(false);
  const [reloadFilteredParteys, setReloadFilteredParteys] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const isMobile = useScreenType();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [lastModfied, setLastModified] = useState(0);

  useEffect(() => {
    if (!isEmpty(activeParteyActions)) {
      let newGuests = [];
      let placeString = "";
      let dateTimeString = "";
      let lastCreatedAtDate = 0;
      let lastCreatedAtPlace = 0;
      getUniqueArray(activeParteyActions).map((action) => {
        if (action.actionString == "addGuest") {
          newGuests.push({
            name: "Gast " + action.actionGuestIndexTarget,
            orders: [],
          });
        }
        if (
          action.actionString == "changeDate" &&
          action.createdAt > lastCreatedAtDate
        ) {
          dateTimeString = action.actionGuestNewName;
          lastCreatedAtDate = action.createdAt;
        }
        if (
          action.actionString == "changePlace" &&
          action.createdAt > lastCreatedAtPlace
        ) {
          placeString = action.actionGuestNewName;
          lastCreatedAtPlace = action.createdAt;
        }
      });
      setGuests(newGuests);
      setDate(dateTimeString);
      setPlace(placeString);
    }
  }, [activeParteyActions]);

  // useEffect für die Synchronisierung der Parteys alle 5 Sekunden
  useEffect(() => {
    const intervalId = setInterval(() => {
      setReloadParteyActions((prev) => !prev); // Zustand toggeln alle 5 Sekunden
    }, 5000); // alle 5 Sekunden

    // Aufräumarbeiten, wenn der Effekt nicht mehr benötigt wird
    return () => clearInterval(intervalId);
  }, []); // Keine Abhängigkeiten, damit das Intervall dauerhaft läuft

  // updateServer
  // useEffect, der jede activeParteyAction änderung mit dem alten state hinsichtlich createdAt vergleicht
  // alle objekte deren createdAt > als lastServerCreatedAt haben, kommen in ein array
  // das array wird nacheinander abgearbeitet und hochgeladen

  // syncWithServer
  // wir fetchen alle 5 sekunden alle parteyActions vom heutigen tag
  // wir checken jede einzelne ob wir sie schon haben. wenn nicht, kommen sie in unseren lokalen state

  // initialize
  // wir fetchen einmal alle parteyActions von gestern und setzen sie auf unseren lokalen state

  /*


  // Funktion zum Senden der Änderungen des aktiven Partey nach 5 Sekunden
  const updateParteyOnServer = async () => {
    const resa = await fetch(
      `${apiURL}/api/parteys/index.php?id=` + activePartey.id,
      {
        method: "POST",
        headers: {
          //   Authorization: `Bearer ${authToken?.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: JSON.stringify({
          guests: activePartey.guests,
          date: activePartey.date,
          place: activePartey.place,
          lastModified: activePartey.lastModified,
        }),
      }
    );

    
    await resa
      .text()
      .then((value) => initializeParteyStates(JSON.parse(value)[0]));
     
    };

    // Funktion zum Abrufen aller Parteys vom Server
    const syncParteysWithServer = async () => {
      const resa = await fetch(`${apiURL}/api/parteys/index.php`, {
        method: "GET",
        headers: {
          //   Authorization: `Bearer ${authToken?.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        //  body: JSON.stringify({}),
      });
  
      await resa.text().then((value) => updateParteysLocal(JSON.parse(value)));
    };
  
    const updateParteysLocal = (val) => {
      if (!isEmpty(activePartey)) {
        const parte = val.find((par) => par.id == activePartey.id);
        if (parte.lastModified > activePartey.lastModified) {
          setActivePartey(parte);
        }
      }
      setParteys(val);
    };
  
    // Debouncing: Warte 5 Sekunden, bevor die Änderungen gesendet werden
    useEffect(() => {
      if(activePartey.lastModified < Math.floor(Date.now() / 1000)){}
      if (activePartey) {
        const timerId = setTimeout(() => {
          updateLastModified();
        }, 5000);
  
        // Cleanup: Timer abbrechen, wenn activePartey sich vorher ändert
        return () => clearTimeout(timerId);
      }
    }, [activePartey]);
  
    const updateLastModified = () => {
      setActivePartey({
        ...activePartey, // Copy all existing properties
        lastModified: Math.floor(Date.now() / 1000), // Update lastModified with the current Unix timestamp in seconds
      });
      setDebouncedPartey({
        ...activePartey, // Copy all existing properties
        lastModified: Math.floor(Date.now() / 1000), // Update lastModified with the current Unix timestamp in seconds
      }); // Setze das debouncedPartey nach 5 Sekunden
    };
  
    // Sobald das debouncedPartey gesetzt wird, wird die Änderung gesendet
    useEffect(() => {
      if (debouncedPartey) {
        if(debouncedPartey.lastModified >= activePartey.lastModified)
        updateParteyOnServer(debouncedPartey);
      }
    }, [debouncedPartey]);
  
    // useEffect für die Synchronisierung der Parteys alle 5 Sekunden
    useEffect(() => {
      const intervalId = setInterval(() => {
        syncParteysWithServer();
      }, 5000); // alle 5 Sekunden
  
      // Aufräumarbeiten, wenn der Effekt nicht mehr benötigt wird
      return () => clearInterval(intervalId);
    }, []);


  */

  const addSavedIDTOLocalStorage = (idNum) => {
    if (savedParteyIDs != null && !savedParteyIDs.includes(idNum)) {
      let helpArr = [...savedParteyIDs];
      helpArr.push(idNum);
      setSavedParteyIDs(helpArr);
      localStorage.setItem("@parteyIDs", JSON.stringify(helpArr));
      console.log("saved");
    } else {
      console.log(savedParteyIDs);
    }
  };

  if (savedParteyIDs == null) {
    const savedParteyString = localStorage.getItem("@parteyIDs");
    if (!isEmpty(savedParteyString)) {
      console.log(JSON.parse(savedParteyString));
      setSavedParteyIDs(JSON.parse(savedParteyString));
    } else {
      setSavedParteyIDs([]);
    }
  }

  const getUniqueArray = (array) => {
    if (isEmpty(array)) {
      return array;
    }
    return array.filter(
      (obj, index, self) =>
        self.findIndex((o) => o.createdAt === obj.createdAt) === index
    );
  };

  const destroySession = () => {
    try {
      localStorage.removeItem("@jwtmentora");
      setAuthToken();
      setCompanyData([]);
    } catch (e) {
      // saving error
    }
  };

  const getData = () => {
    try {
      const jsonValue = localStorage.getItem("@jwtmentora");
      var resJSON = jsonValue.substring(1, jsonValue.length - 1);
      setAuthToken({ JWT: resJSON });
    } catch (e) {
      // error reading value
    }
  };

  if (isEmpty(authToken) && localStorage.getItem("@jwtmentora")) {
    getData();
  }

  const signIn = async () => {
    let helpobject = {};

    const setJWT = (data) => {
      helpobject = data;
    };

    const storeData = (value) => {
      try {
        localStorage.setItem("@jwtmentora", JSON.stringify(value));
        console.log(value);
      } catch (e) {
        // saving error
      }
    };
    const res = await fetch(`${apiURL}/api/authenticate`, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    });

    // check if response exists
    if (res.status >= 0 && res.status <= 299) {
      const jwt = await res.text();
      // check if response has jwt
      if (jwt.length > 50) {
        setJWT(jwt);
        storeData(helpobject);
        console.log("localstorage erfolgreich");
        setAuthToken(setAuthToken({ JWT: helpobject }));
        console.log("StoreData ist Da. Redirect!");
      } else {
        alert("Login fehlgeschlagen");
      }
      setIsUploading(false);
    } else {
      // @Todo: Handle errors
      console.log(res.status, res.statusText);
      setIsUploading(false);
    }
  };

  const signUp = async () => {
    let helpobject = {};

    const setJWT = (data) => {
      helpobject = data;
      console.log(helpobject);
    };
    const storeData = (value) => {
      try {
        localStorage.setItem("@jwtmentora", JSON.stringify(value));
        console.log(value);
      } catch (e) {
        // saving error
      }
    };

    const res = await fetch(`${apiURL}/api/signup.php`, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({
        email: email,
        password: password,
        username: username,
      }),
    });

    // check if response exists
    if (res.status >= 0 && res.status <= 299) {
      const jwt = await res.text();
      // check if response has jwt
      if (jwt.length > 50) {
        setJWT(jwt);
        storeData(helpobject);
        console.log("localstorage erfolgreich");
        setAuthToken(setAuthToken({ JWT: helpobject }));
        console.log("StoreData ist Da. Redirect!");
      } else {
        alert("Registrierung fehlgeschlagen");
      }
      setIsUploading(false);
    } else {
      // @Todo: Handle errors
      console.log(res.status, res.statusText);
      setIsUploading(false);
    }
  };

  const {
    data: userData,
    loading: loadingUser,
    error: errorUser,
  } = useFetch(
    `${apiURL}/api/users/`,
    authToken,
    "GET",
    null,
    [authToken] // Additional dependencies, if needed
  );

  const {
    data: companyData,
    loading: loadingCompanyData,
    error: errorCompanyData,
    setData: setCompanyData,
  } = useFetch(
    `${apiURL}/api/companies/?id=` + userData.companyID,
    authToken,
    "GET",
    null,
    [userData, authToken, reload] // Additional dependencies, if needed
  );

  const {
    data: products,
    loading: loadingProducts,
    error: errorProducts,
    setData: setProducts,
  } = useFetch(
    `${apiURL}/api/products/index.php`,
    authToken,
    "GET",
    null,
    [authToken, reload] // Additional dependencies, if needed
  );

  const {
    data: parteys,
    loading: loadingParteys,
    error: errorParteys,
    setData: setParteys,
  } = useFetch(
    `${apiURL}/api/parteys/index.php`,
    authToken,
    "GET",
    null,
    [authToken, reload, reloadFilteredParteys] // Additional dependencies, if needed
  );

  const {
    data: parteyActions,
    loading: loadingParteyActions,
    error: errorParteyActions,
    setData: setParteyActions,
  } = useFetch(
    `${apiURL}/api/parteyActions/index.php?partey_id=` + activePartey.id,
    authToken,
    "GET",
    null,
    [authToken, activePartey, reloadParteyActions] // Additional dependencies, if needed
  );

  useEffect(() => {
    const mergeAndFilterByCreatedAt = (array1, array2) => {
      // Merge the two arrays
      const mergedArray = [...array1, ...array2];

      // Filter to ensure unique 'createdAt' values
      const uniqueArray = mergedArray.filter(
        (obj, index, self) =>
          index === self.findIndex((o) => o.createdAt === obj.createdAt)
      );

      return uniqueArray;
    };
    if (!isEmpty(parteyActions) && !isEmpty(activePartey)) {
      // alle zusammenführen aber unique bleiben
      let helpArr = [...parteyActions];
      let helpArr2 = [...activeParteyActions];
      setActiveParteyActions(mergeAndFilterByCreatedAt(helpArr, helpArr2));
    }
  }, [parteyActions, activePartey]);

  const productAlt = [
    {
      internal_id: "1",
      variationVonID: "",
      titel: "Entrecote 200g",
      zustand: "frisch",
      gewicht: "500",
      bilder: JSON.stringify([
        {
          url: "https://www.grillchef.servist.de/api/images/uploads/9b86668575b5a2a6a76bb4b05079ea89.jpg",
        },
      ]),
      vorrat: "10",
      bezugspunkt:
        "https://shop.rewe.de/p/hareico-grill-bratwurst-8x62-5g/5807727",
      einkaufspreis: "619",
      verkaufspreis: "1199",
      preis_pro_kilo: "2398",
      category: "Steaks",
      beschreibung:
        "Herzhaft-würzig mit feinen Kräutern, der Klassiker unter den Grillwürsten.",
      energie_kj: "1.180",
      energie_kcal: "285",
      fett: "25",
      gesaettigt_fett: "10,5",
      einfachungesaettigt_fett: "",
      mehrfachungesaettigt_fett: "",
      kohlenhydrate: "1",
      zucker: "0,5",
      eiweiss: "14",
      salz: "2",
      zutatenliste:
        "Zutaten: Schweinefleisch (90%), Trinkwasser, Speisesalz, Gewürze (enthalten SENF und SELLERIE), Traubenzucker, Glukosesirup, Stabilisator: Natriumcitrate, Rinder-Collagendarm.\nAllergenhinweise: Sellerie, SenfEnthält: Sellerie und Sellerieerzeugnisse. Senf- und Senferzeugnisse.",
      ballaststoffe: "",
      calcium: "20",
      natrium: "0,83",
      nettofuellmenge: "500",
      hersteller: "Döllinghareico GmbH & Co. KG",
      createdAt: "",
      status: "1",
    },
    {
      internal_id: "2",
      variationVonID: "",
      titel: "T-Bone 200g",
      zustand: "frisch",
      gewicht: "500",
      bilder: JSON.stringify([
        {
          url: "https://www.grillchef.servist.de/api/images/uploads/f7a389e66b69a3813ef5b7d48e7f852a.jpg",
        },
      ]),
      vorrat: "10",
      bezugspunkt:
        "https://shop.rewe.de/p/hareico-grill-bratwurst-8x62-5g/5807727",
      einkaufspreis: "619",
      verkaufspreis: "1199",
      preis_pro_kilo: "2398",
      category: "Steaks",
      beschreibung:
        "Herzhaft-würzig mit feinen Kräutern, der Klassiker unter den Grillwürsten.",
      energie_kj: "1.180",
      energie_kcal: "285",
      fett: "25",
      gesaettigt_fett: "10,5",
      einfachungesaettigt_fett: "",
      mehrfachungesaettigt_fett: "",
      kohlenhydrate: "1",
      zucker: "0,5",
      eiweiss: "14",
      salz: "2",
      zutatenliste:
        "Zutaten: Schweinefleisch (90%), Trinkwasser, Speisesalz, Gewürze (enthalten SENF und SELLERIE), Traubenzucker, Glukosesirup, Stabilisator: Natriumcitrate, Rinder-Collagendarm.\nAllergenhinweise: Sellerie, SenfEnthält: Sellerie und Sellerieerzeugnisse. Senf- und Senferzeugnisse.",
      ballaststoffe: "",
      calcium: "20",
      natrium: "0,83",
      nettofuellmenge: "500",
      hersteller: "Döllinghareico GmbH & Co. KG",
      createdAt: "",
      status: "1",
    },
    {
      internal_id: "3",
      variationVonID: "",
      titel: "Nürnberger Wurst",
      zustand: "frisch",
      gewicht: "500",
      bilder: JSON.stringify([
        {
          url: "https://www.grillchef.servist.de/api/images/placeholder/wurst.jpg",
        },
      ]),
      vorrat: "10",
      bezugspunkt:
        "https://shop.rewe.de/p/hareico-grill-bratwurst-8x62-5g/5807727",
      einkaufspreis: "619",
      verkaufspreis: "1199",
      preis_pro_kilo: "2398",
      category: "Wuerstchen",
      beschreibung:
        "Herzhaft-würzig mit feinen Kräutern, der Klassiker unter den Grillwürsten.",
      energie_kj: "1.180",
      energie_kcal: "285",
      fett: "25",
      gesaettigt_fett: "10,5",
      einfachungesaettigt_fett: "",
      mehrfachungesaettigt_fett: "",
      kohlenhydrate: "1",
      zucker: "0,5",
      eiweiss: "14",
      salz: "2",
      zutatenliste:
        "Zutaten: Schweinefleisch (90%), Trinkwasser, Speisesalz, Gewürze (enthalten SENF und SELLERIE), Traubenzucker, Glukosesirup, Stabilisator: Natriumcitrate, Rinder-Collagendarm.\nAllergenhinweise: Sellerie, SenfEnthält: Sellerie und Sellerieerzeugnisse. Senf- und Senferzeugnisse.",
      ballaststoffe: "",
      calcium: "20",
      natrium: "0,83",
      nettofuellmenge: "500",
      hersteller: "Döllinghareico GmbH & Co. KG",
      createdAt: "",
      status: "1",
    },
    {
      internal_id: "4",
      variationVonID: "",
      titel: "Bratwurst",
      zustand: "frisch",
      gewicht: "500",
      bilder: JSON.stringify([
        {
          url: "https://www.grillchef.servist.de/api/images/placeholder/wurst.jpg",
        },
      ]),
      vorrat: "10",
      bezugspunkt:
        "https://shop.rewe.de/p/hareico-grill-bratwurst-8x62-5g/5807727",
      einkaufspreis: "619",
      verkaufspreis: "1199",
      preis_pro_kilo: "2398",
      category: "Wuerstchen",
      beschreibung:
        "Herzhaft-würzig mit feinen Kräutern, der Klassiker unter den Grillwürsten.",
      energie_kj: "1.180",
      energie_kcal: "285",
      fett: "25",
      gesaettigt_fett: "10,5",
      einfachungesaettigt_fett: "",
      mehrfachungesaettigt_fett: "",
      kohlenhydrate: "1",
      zucker: "0,5",
      eiweiss: "14",
      salz: "2",
      zutatenliste:
        "Zutaten: Schweinefleisch (90%), Trinkwasser, Speisesalz, Gewürze (enthalten SENF und SELLERIE), Traubenzucker, Glukosesirup, Stabilisator: Natriumcitrate, Rinder-Collagendarm.\nAllergenhinweise: Sellerie, SenfEnthält: Sellerie und Sellerieerzeugnisse. Senf- und Senferzeugnisse.",
      ballaststoffe: "",
      calcium: "20",
      natrium: "0,83",
      nettofuellmenge: "500",
      hersteller: "Döllinghareico GmbH & Co. KG",
      createdAt: "",
      status: "1",
    },
    {
      internal_id: "5",
      variationVonID: "",
      titel: "Cola",
      zustand: "frisch",
      gewicht: "500",
      bilder: JSON.stringify([
        {
          url: "https://www.grillchef.servist.de/api/images/placeholder/getraenke.png",
        },
      ]),
      vorrat: "10",
      bezugspunkt:
        "https://shop.rewe.de/p/hareico-grill-bratwurst-8x62-5g/5807727",
      einkaufspreis: "619",
      verkaufspreis: "1199",
      preis_pro_kilo: "2398",
      category: "Getränke",
      beschreibung:
        "Herzhaft-würzig mit feinen Kräutern, der Klassiker unter den Grillwürsten.",
      energie_kj: "1.180",
      energie_kcal: "285",
      fett: "25",
      gesaettigt_fett: "10,5",
      einfachungesaettigt_fett: "",
      mehrfachungesaettigt_fett: "",
      kohlenhydrate: "1",
      zucker: "0,5",
      eiweiss: "14",
      salz: "2",
      zutatenliste:
        "Zutaten: Schweinefleisch (90%), Trinkwasser, Speisesalz, Gewürze (enthalten SENF und SELLERIE), Traubenzucker, Glukosesirup, Stabilisator: Natriumcitrate, Rinder-Collagendarm.\nAllergenhinweise: Sellerie, SenfEnthält: Sellerie und Sellerieerzeugnisse. Senf- und Senferzeugnisse.",
      ballaststoffe: "",
      calcium: "20",
      natrium: "0,83",
      nettofuellmenge: "500",
      hersteller: "Döllinghareico GmbH & Co. KG",
      createdAt: "",
      status: "1",
    },
    {
      internal_id: "6",
      variationVonID: "",
      titel: "Cola Zero",
      zustand: "frisch",
      gewicht: "500",
      bilder: JSON.stringify([
        {
          url: "https://www.grillchef.servist.de/api/images/placeholder/getraenke.png",
        },
      ]),
      vorrat: "10",
      bezugspunkt:
        "https://shop.rewe.de/p/hareico-grill-bratwurst-8x62-5g/5807727",
      einkaufspreis: "619",
      verkaufspreis: "1199",
      preis_pro_kilo: "2398",
      category: "Getränke",
      beschreibung:
        "Herzhaft-würzig mit feinen Kräutern, der Klassiker unter den Grillwürsten.",
      energie_kj: "1.180",
      energie_kcal: "285",
      fett: "25",
      gesaettigt_fett: "10,5",
      einfachungesaettigt_fett: "",
      mehrfachungesaettigt_fett: "",
      kohlenhydrate: "1",
      zucker: "0,5",
      eiweiss: "14",
      salz: "2",
      zutatenliste:
        "Zutaten: Schweinefleisch (90%), Trinkwasser, Speisesalz, Gewürze (enthalten SENF und SELLERIE), Traubenzucker, Glukosesirup, Stabilisator: Natriumcitrate, Rinder-Collagendarm.\nAllergenhinweise: Sellerie, SenfEnthält: Sellerie und Sellerieerzeugnisse. Senf- und Senferzeugnisse.",
      ballaststoffe: "",
      calcium: "20",
      natrium: "0,83",
      nettofuellmenge: "500",
      hersteller: "Döllinghareico GmbH & Co. KG",
      createdAt: "",
      status: "1",
    },
    {
      internal_id: "7",
      variationVonID: "",
      titel: "Heineken",
      zustand: "frisch",
      gewicht: "500",
      bilder: JSON.stringify([
        {
          url: "https://www.grillchef.servist.de/api/images/placeholder/getraenke.png",
        },
      ]),
      vorrat: "10",
      bezugspunkt:
        "https://shop.rewe.de/p/hareico-grill-bratwurst-8x62-5g/5807727",
      einkaufspreis: "619",
      verkaufspreis: "1199",
      preis_pro_kilo: "2398",
      category: "Getränke",
      beschreibung:
        "Herzhaft-würzig mit feinen Kräutern, der Klassiker unter den Grillwürsten.",
      energie_kj: "1.180",
      energie_kcal: "285",
      fett: "25",
      gesaettigt_fett: "10,5",
      einfachungesaettigt_fett: "",
      mehrfachungesaettigt_fett: "",
      kohlenhydrate: "1",
      zucker: "0,5",
      eiweiss: "14",
      salz: "2",
      zutatenliste:
        "Zutaten: Schweinefleisch (90%), Trinkwasser, Speisesalz, Gewürze (enthalten SENF und SELLERIE), Traubenzucker, Glukosesirup, Stabilisator: Natriumcitrate, Rinder-Collagendarm.\nAllergenhinweise: Sellerie, SenfEnthält: Sellerie und Sellerieerzeugnisse. Senf- und Senferzeugnisse.",
      ballaststoffe: "",
      calcium: "20",
      natrium: "0,83",
      nettofuellmenge: "500",
      hersteller: "Döllinghareico GmbH & Co. KG",
      createdAt: "",
      status: "1",
    },
    {
      internal_id: "8",
      variationVonID: "",
      titel: "Knobi Baguette Knoblauch",
      zustand: "frisch",
      gewicht: "500",
      bilder: JSON.stringify([
        {
          url: "https://www.grillchef.servist.de/api/images/placeholder/brot.png",
        },
      ]),
      vorrat: "10",
      bezugspunkt:
        "https://shop.rewe.de/p/hareico-grill-bratwurst-8x62-5g/5807727",
      einkaufspreis: "619",
      verkaufspreis: "1199",
      preis_pro_kilo: "2398",
      category: "Beilagen",
      beschreibung:
        "Herzhaft-würzig mit feinen Kräutern, der Klassiker unter den Grillwürsten.",
      energie_kj: "1.180",
      energie_kcal: "285",
      fett: "25",
      gesaettigt_fett: "10,5",
      einfachungesaettigt_fett: "",
      mehrfachungesaettigt_fett: "",
      kohlenhydrate: "1",
      zucker: "0,5",
      eiweiss: "14",
      salz: "2",
      zutatenliste:
        "Zutaten: Schweinefleisch (90%), Trinkwasser, Speisesalz, Gewürze (enthalten SENF und SELLERIE), Traubenzucker, Glukosesirup, Stabilisator: Natriumcitrate, Rinder-Collagendarm.\nAllergenhinweise: Sellerie, SenfEnthält: Sellerie und Sellerieerzeugnisse. Senf- und Senferzeugnisse.",
      ballaststoffe: "",
      calcium: "20",
      natrium: "0,83",
      nettofuellmenge: "500",
      hersteller: "Döllinghareico GmbH & Co. KG",
      createdAt: "",
      status: "1",
    },

    {
      internal_id: "9",
      variationVonID: "",
      titel: "Knobi Baguette Kräuter",
      zustand: "frisch",
      gewicht: "500",
      bilder: JSON.stringify([
        {
          url: "https://www.grillchef.servist.de/api/images/placeholder/brot.png",
        },
      ]),
      vorrat: "10",
      bezugspunkt:
        "https://shop.rewe.de/p/hareico-grill-bratwurst-8x62-5g/5807727",
      einkaufspreis: "619",
      verkaufspreis: "1199",
      preis_pro_kilo: "2398",
      category: "Beilagen",
      beschreibung:
        "Herzhaft-würzig mit feinen Kräutern, der Klassiker unter den Grillwürsten.",
      energie_kj: "1.180",
      energie_kcal: "285",
      fett: "25",
      gesaettigt_fett: "10,5",
      einfachungesaettigt_fett: "",
      mehrfachungesaettigt_fett: "",
      kohlenhydrate: "1",
      zucker: "0,5",
      eiweiss: "14",
      salz: "2",
      zutatenliste:
        "Zutaten: Schweinefleisch (90%), Trinkwasser, Speisesalz, Gewürze (enthalten SENF und SELLERIE), Traubenzucker, Glukosesirup, Stabilisator: Natriumcitrate, Rinder-Collagendarm.\nAllergenhinweise: Sellerie, SenfEnthält: Sellerie und Sellerieerzeugnisse. Senf- und Senferzeugnisse.",
      ballaststoffe: "",
      calcium: "20",
      natrium: "0,83",
      nettofuellmenge: "500",
      hersteller: "Döllinghareico GmbH & Co. KG",
      createdAt: "",
      status: "1",
    },
  ];

  // Check If Local Changes Were Made
  useEffect(() => {
    let localLastModified = lastModfied;
    let newestModified = 0;

    if (
      !isEmpty(activeParteyActions) &&
      activePartey.id == activeParteyActions[0].partey_id
    ) {
      let newChanges = [];
      getUniqueArray(parteyActions).map((action) => {
        if (parseInt(action.createdAt) > localLastModified) {
          localLastModified = parseInt(action.createdAt);
        }
      });
      getUniqueArray(activeParteyActions).map((action) => {
        if (parseInt(action.createdAt) > localLastModified) {
          newestModified = action.createdAt;
          newChanges.push(action);
        }
      });
      setLastModified(newestModified);
      console.log(newChanges);
      getUniqueArray(newChanges).map((change) => uploadParteyChanges(change));
      // ich sollte lastModified als global state setzen. dann würde immer geguckt werden, wann ich zuletzt ne änderung hochgeladen habe.
      //  setParteyActions([]);
      // alte server parteyActions checken wo createdAt war
      // alle lokalen activeParteyActions durchgehen wo neueres createdAt und jedes einzelne in newChanges pushen
    }
  }, [activeParteyActions, parteyActions]);

  const uploadParteyChanges = async (changeObject) => {
    console.log(changeObject.actionGuestIndexTarget);
    const resa = await fetch(`${apiURL}/api/parteyActions/index.php`, {
      method: "PUT",
      headers: {
        //   Authorization: `Bearer ${authToken?.JWT}`,
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify({
        partey_id: changeObject.partey_id,
        createdAt: changeObject.createdAt,
        actionString: !isEmpty(changeObject.actionString)
          ? changeObject.actionString
          : "",
        actionGuestIndexTarget: changeObject.actionGuestIndexTarget,
        actionGuestProductInternalID: !isEmpty(
          changeObject.actionGuestProductInternalID
        )
          ? changeObject.actionGuestProductInternalID
          : "",
        actionGuestNewName: !isEmpty(changeObject.actionGuestNewName)
          ? changeObject.actionGuestNewName
          : "",
      }),
    });

    //  await resa.text().then((value) => alert(value));
  };

  const pics = [
    "https://www.cloud.leefes.com/api/images/placeholder/profilbild.svg",
    "https://www.grillchef.servist.de/api/images/placeholder/pics-1.png",
    "https://www.grillchef.servist.de/api/images/placeholder/pics-2.png",
    "https://www.grillchef.servist.de/api/images/placeholder/pics-3.png",
    "https://www.grillchef.servist.de/api/images/placeholder/pics-4.png",
    "https://www.grillchef.servist.de/api/images/placeholder/pics-5.png",
    "https://www.grillchef.servist.de/api/images/placeholder/pics-6.png",
    "https://www.grillchef.servist.de/api/images/placeholder/pics-7.png",
    "https://www.grillchef.servist.de/api/images/placeholder/pics-8.png",
    "https://www.grillchef.servist.de/api/images/placeholder/pics-9.png",
    "https://www.grillchef.servist.de/api/images/placeholder/pics-10.png",
    "https://www.grillchef.servist.de/api/images/placeholder/pics-11.png",
    "https://www.grillchef.servist.de/api/images/placeholder/pics-12.png",
    "https://www.grillchef.servist.de/api/images/placeholder/pics-13.png",
    "https://www.grillchef.servist.de/api/images/placeholder/pics-14.png",
    "https://www.grillchef.servist.de/api/images/placeholder/pics-15.png",
    "https://www.grillchef.servist.de/api/images/placeholder/pics-16.png",
    "https://www.grillchef.servist.de/api/images/placeholder/pics-17.png",
    "https://www.grillchef.servist.de/api/images/placeholder/pics-18.png",
    "https://www.grillchef.servist.de/api/images/placeholder/pics-19.png",
  ];

  useEffect(() => {
    if (!isEmpty(parteys)) {
      const filterByMatchingIds = (arrayOfObjects, arrayOfStrings) => {
        return arrayOfObjects.filter((obj) =>
          arrayOfStrings.includes(obj.id.toString())
        );
      };
      const savedParteyString = localStorage.getItem("@parteyIDs");
      if (!isEmpty(savedParteyString)) {
        const reducedArray = filterByMatchingIds(
          parteys,
          JSON.parse(savedParteyString)
        );

        setFilteredParteys(reducedArray);
      }
    }
  }, [parteys, savedParteyIDs, reloadFilteredParteys]);

  return (
    <div className="app">
      <LanguageProvider>
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  products={products}
                  cart={cart}
                  setCart={setCart}
                  searchInput={searchInput}
                  setSearchInput={setSearchInput}
                  scrollPosition={scrollPosition}
                  setScrollPosition={setScrollPosition}
                  guests={guests}
                  setGuests={setGuests}
                  parteys={filteredParteys}
                  activePartey={activePartey}
                  setActivePartey={setActivePartey}
                  uneditedParteyState={uneditedParteyState}
                  setUneditedParteyState={setUneditedParteyState}
                  activeParteyActions={getUniqueArray(activeParteyActions)}
                  setActiveParteyActions={setActiveParteyActions}
                  date={date}
                  setDate={setDate}
                  place={place}
                  setPlace={setPlace}
                  savedParteyIDs={savedParteyIDs}
                  setSavedParteyIDs={setSavedParteyIDs}
                  setReloadFilteredParteys={setReloadFilteredParteys}
                  reloadFilteredParteys={reloadFilteredParteys}
                  setParteyActions={setParteyActions}
                  parteyActions={parteyActions}
                />
              }
            />
            <Route
              path="/:parteyid"
              element={
                <Partey
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  products={productAlt}
                  cart={cart}
                  setCart={setCart}
                  searchInput={searchInput}
                  setSearchInput={setSearchInput}
                  scrollPosition={scrollPosition}
                  setScrollPosition={setScrollPosition}
                  guests={guests}
                  setGuests={setGuests}
                  parteys={parteys}
                  setParteys={setParteys}
                  activePartey={activePartey}
                  setActivePartey={setActivePartey}
                  uneditedParteyState={uneditedParteyState}
                  setUneditedParteyState={setUneditedParteyState}
                  activeParteyActions={getUniqueArray(activeParteyActions)}
                  setActiveParteyActions={setActiveParteyActions}
                  date={date}
                  setDate={setDate}
                  place={place}
                  setPlace={setPlace}
                  pics={pics}
                  savedParteyIDs={savedParteyIDs}
                  setSavedParteyIDs={setSavedParteyIDs}
                  setReloadFilteredParteys={setReloadFilteredParteys}
                  reloadFilteredParteys={reloadFilteredParteys}
                />
              }
            />

            <Route
              path="/:parteyid/guest/:id"
              element={
                <ProductList
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  products={productAlt}
                  cart={cart}
                  setCart={setCart}
                  searchInput={searchInput}
                  setSearchInput={setSearchInput}
                  scrollPosition={scrollPosition}
                  setScrollPosition={setScrollPosition}
                  guests={guests}
                  parteys={parteys}
                  setParteys={setParteys}
                  setGuests={setGuests}
                  activePartey={activePartey}
                  setActivePartey={setActivePartey}
                  uneditedParteyState={uneditedParteyState}
                  setUneditedParteyState={setUneditedParteyState}
                  activeParteyActions={getUniqueArray(activeParteyActions)}
                  setActiveParteyActions={setActiveParteyActions}
                  date={date}
                  setDate={setDate}
                  place={place}
                  setPlace={setPlace}
                  savedParteyIDs={savedParteyIDs}
                  setSavedParteyIDs={setSavedParteyIDs}
                />
              }
            />
            <Route
              path="/product/:id"
              element={
                <Product
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  products={products}
                  cart={cart}
                  setCart={setCart}
                />
              }
            />
            <Route
              path="/cart/*"
              element={
                <Cart
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  products={products}
                  cart={cart}
                  setCart={setCart}
                />
              }
            />
            <Route
              path="/checkout/*"
              element={
                <Checkout
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  products={products}
                  cart={cart}
                  setCart={setCart}
                />
              }
            />
          </Routes>
          <></>
        </Router>
      </LanguageProvider>
    </div>
  );
}
